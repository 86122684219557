@import './variables'; 

.grid-row {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: $spacing-xl;
  width: 100%;
  
  &.align-self-start {
    align-self: start;
    width: 100%;
  }

  &.xxs {
    row-gap: $spacing-xxs;
  }
  &.xs {
    row-gap: $spacing-xs;
  }
  &.s {
    row-gap: $spacing-s;
  }
  &.m {
    row-gap: $spacing-m;
  }
  &.l {
    row-gap: $spacing-l;
  }
  &.xl {
    row-gap: $spacing-xl;
  }
  &.d-l {
    row-gap: calc($spacing-l * 2);
  }
  &.d-xl {
    row-gap: calc($spacing-xl * 2);
  }
  &.xxl {
    row-gap: $spacing-xxl;
  }
  &.none {
    row-gap: 0px;
  }

  &.justify-items-center {
    justify-items: center;
  }

  &.justify-items-start {
    justify-items: start;
  }

  &.align-content-start {
    align-content: start;
  }
  
  &.align-items-center {
    align-items: center;
  }

  &.align-items-start {
    align-items: start;
  }
}

.grid-column {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: $spacing-m;
  column-gap: $spacing-m;

  &.template-1fr-1fr {
    grid-template-columns: repeat(2, 1fr);
  }
  &.template-1fr-1fr-minmax {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  &.template-1fr-1fr-1fr {
    grid-template-columns: repeat(3, 1fr);
  }
  &.template-1fr-1fr-1fr-minmax {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  &.template-1frx4 {
    grid-template-columns: repeat(4, 1fr);
  }
  &.template-1fr-mc-1fr {
    grid-template-columns: 1fr max-content 1fr;
  }
  &.template-mc-mc { 
    grid-template-columns: max-content max-content;
  }
  &.template-mc-mc-1fr { 
    grid-template-columns: max-content max-content 1fr;
  }
  &.template-mc-mc-mc { 
    grid-template-columns: max-content max-content max-content;
  }
  &.template-mc-1fr-mc { 
    grid-template-columns: max-content 1fr max-content;
  }
  &.template-mc-1fr-mc-mc { 
    grid-template-columns: max-content 1fr max-content max-content;
  }
  &.template-mc-1fr { 
    grid-template-columns: max-content 1fr;
  }
  &.template-mc-1fr-1fr { 
    grid-template-columns: max-content 1fr 1fr;
  }
  &.template-1fr-mc { 
    grid-template-columns: 1fr max-content;
  }
  &.template-1fr-mc-mc { 
    grid-template-columns: 1fr max-content max-content;
  }

  &.xxs {
    row-gap: $spacing-xxs;
    column-gap: $spacing-xxs;
  }
  &.xs {
    row-gap: $spacing-xs;
    column-gap: $spacing-xs;
  }
  &.s {
    row-gap: $spacing-s;
    column-gap: $spacing-s;
  }
  &.m {
    row-gap: $spacing-m;
    column-gap: $spacing-m;
  }
  &.l {
    row-gap: $spacing-l;
    column-gap: $spacing-l;
  }
  &.xl {
    row-gap: $spacing-xl;
    column-gap: $spacing-xl;
  }
  &.xl-m {
    row-gap: $spacing-xl;
    column-gap: $spacing-m;
  }
  &.d-xl {
    row-gap: calc($spacing-xl * 2);
    column-gap: calc($spacing-xl * 2);
  }
  &.xxl {
    row-gap: $spacing-xxl;
    column-gap: $spacing-xxl;
  }
  &.none {
    row-gap: 0;
    column-gap: 0;
  }

  &.align-items-end {
    align-items: end;
  }
  &.align-items-center {
    align-items: center;
  }
  &.justify-items-start {
    justify-items: self-start;
  }
  &.justify-items-end {
    justify-items: self-end;
  }
  &.justify-content-end {
    justify-content: end;
  }
  &.align-self-start {
    align-self: start;
  }
  &.align-items-start {
    align-items: start;
  }
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;

  &.column {
    flex-direction: column;
  }
  &.flex-wrap {
    flex-wrap: wrap;
  }

  &.align-items-start {
    align-items: flex-start;
  }
  &.align-items-end {
    align-items: flex-end;
  }
  &.align-items-center {
    align-items: center;
  }

  &.justify-content-center {
    justify-content: center;
  }
  &.justify-content-start {
    justify-content: start;
  }
  &.justify-content-end {
    justify-content: end;
  }
  &.justify-content-sp {
    justify-content: space-between;
  }
  &.justify-content-sa {
    justify-content: space-around;
  }

  &.d-xl {
    gap: calc($spacing-xl * 2);
  }
  &.xl {
    gap: $spacing-xl;
  }
  &.l {
    gap: $spacing-m;
  }
  &.m {
    gap: $spacing-m;
  }
  &.s {
    gap: $spacing-s;
  }
  &.xs {
    gap: $spacing-xs;
  }
  &.xxs {
    gap: $spacing-xxs;
  }
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  thead tr {
    height: 48px;
    background: inherit;

    th {
      border-bottom: 1px solid $palette-grey200;
      border-top: 1px solid $palette-grey200;
      text-align: start;
      padding: $spacing-s;
    }

    th:first-child {
      border-left: 1px solid $palette-grey200;
    }

    th:last-child {
      border-right: 1px solid $palette-grey200;
    }
  }

  tbody tr {
    height: 48px;
    background: $palette-body;

    td {
      padding: $spacing-s;
      vertical-align: middle;
      border-bottom: 1px solid $palette-grey200;
    }

    td:first-child {
      border-left: 1px solid $palette-grey200;
    }

    td:last-child {
      border-right: 1px solid $palette-grey200;
    }
    
    &.clickable-row {
      &:hover {
        background: $palette-grey50;
        cursor: pointer;
        .table-ChevronRight {
          path {
            fill: $palette-grey900;
          }
        }
      }
    }
  }

  .table-ChevronRight {
    path {
      fill: $palette-grey400;
    }
  }
}

.table__background-header {
  thead tr {
    background: $palette-grey50;
  }
}

.table__with-hover {
  tbody tr {
    &:hover {
      background: $palette-grey50;
      .table-ChevronRight {
        path {
          fill: $palette-grey900;
        }
      }
    }
  }
}

.table__with-hover-total {
  tbody tr:hover:not(:last-child) {
    background: $palette-grey50;
    .table-ChevronRight {
      path {
        fill: $palette-grey900;
      }
    }
  }
}

.table__bg-last-row-one {
  tbody tr:last-child {
    background: $palette-grey50;
  }
}


.table__bg-last-row-two {
  tbody tr:nth-last-child(2) {
    background: $palette-grey50;
  }

  tbody tr:last-child {
    background: $palette-grey50;
  }
}

.table-hover-head {
  thead pre,
  thead span {
    color: $palette-grey500;
  }
  thead th:hover:not(:last-child) {
    background: $palette-grey100;
    pre,
    span {
      color: $palette-grey900;
    }
  }
}

.table.active-col-1 {
  tbody tr td:nth-child(1) {
    background: $palette-grey50;
  }
}

.table.active-col-2 {
  tbody tr td:nth-child(2) {
    background: $palette-grey50;
  }
}

.table.active-col-3 {
  tbody tr td:nth-child(3) {
    background: $palette-grey50;
  }
}

.table.active-col-4 {
  tbody tr td:nth-child(4) {
    background: $palette-grey50;
  }
}

.table.active-col-5 {
  tbody tr td:nth-child(5) {
    background: $palette-grey50;
  }
}

.table.active-col-6 {
  tbody tr td:nth-child(6) {
    background: $palette-grey50;
  }
}

.table.active-col-7 {
  tbody tr td:nth-child(7) {
    background: $palette-grey50;
  }
}

.table.active-col-8 {
  tbody tr td:nth-child(8) {
    background: $palette-grey50;
  }
}

.table.active-col-9 {
  tbody tr td:nth-child(9) {
    background: $palette-grey50;
  }
}


.table-sell {
  width: 100%;
  text-align: start;

  &.end {
    text-align: end;
  }

  &.center {
    text-align: center;
  }
}

.table-without-head {
  thead {
    display: none;
  }

  tbody {
    tr:first-child {
      td {
       border-top: 1px solid $palette-grey200;
      }
      td:first-child {
        border-top-left-radius: $radius-s;
      }
      td:last-child {
        border-top-right-radius: $radius-s;
      }
    }
  }
}

// .table__th-sort-styles {
//   thead tr th {
//     display: table-cell;
//     align-items: center;
//     gap: $spacing-xs;
//   }
// }

.image-container {
  width: 96px;
  min-width: 96px;
  height: 96px;
  background-color: $palette-body;
  border-radius: $radius-round;
  border: 1px solid $palette-grey100;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: contain;
  }

  &.width-16 {
    width: 16px;
    min-width: 16px;
    height: 16px;
  }

  &.width-24 {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }

  &.width-48 {
    width: 48px;
    min-width: 48px;
    height: 48px;
  }

  &.hide-border {
    border: none;
  }

  &.avatar {
    background-color: $palette-grey100;
  }

  &.radius-s {
    border-radius: $radius-s;
  }

  &.radius-xs {
    border-radius: $radius-xs;
  }

  &.radius-m {
    border-radius: $radius-m;
  }
}

.tag-item {
  padding: $spacing-xxs $spacing-xs;
  width: max-content;
  display: flex;
  background-color: $palette-grey100;
  border-radius: $radius-xs;

  &.no-wrap {
    text-wrap: nowrap
  }

  &.radius-xxl {
    border-radius: $radius-xxl;
  }

  &.primary100 {
    background-color: $palette-primary100;
  }

  &.primary600 {
    background-color: $palette-primary600;
  }

  &.success100 {
    background-color: $palette-success100;
  }

  &.warning100 {
    background-color: $palette-warning100;
  }

  &.alert100 {
    background-color: $palette-alert100;
  }
}

.tag-list-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $spacing-xs;
}

.blur-container {
  filter: blur(6px);
  user-select: none;
  pointer-events: none;
}

.widget-wrapper {
  width: 424px;
  background-color: $palette-body;
  box-shadow: 0px 4px 16px rgba(31, 41, 55, 0.1);
  border-radius: $radius-m;

  &.has-padding {
    padding: $spacing-xl;
  }

  &.has-padding-l {
    padding: $spacing-l;
  }
  
  &.sticky {
    position: sticky;
    top: calc($spacing-xl + $header_height);
  }
  &.width-448 {
    width: 448px;
    min-width: 448px;
  }

  &.width-363 {
    width: 363px;
    min-width: 363px;
  }

  &.width-384 {
    width: 384px;
    min-width: 384px;
  }

  &.hide-shadow {
    box-shadow: none;
  }
}

.dual-sticky-elements {
  display: grid;
  grid-template-rows: max-content max-content;
  row-gap: $spacing-xl;
  position: sticky;
  top: calc($spacing-xl + $header_height);
}

.text-align-center {
  text-align: center;
}

.base-card-view {
  background-color: $palette-body;
  border: 1px solid $palette-grey200;
  box-sizing: border-box;
  border-radius: $radius-m;
  padding: calc($spacing-m - 1px) $spacing-m;

  &.radius-s {
    border-radius: $radius-s;
  }

  &.hover {
    cursor: pointer;
    &:hover {
      background: $palette-grey50;
    }
  }

  &.active {
    border: 2px solid $palette-primary600;
    padding: calc($spacing-m - 2px) $spacing-m;
  }

  &.disabled {
    background: $palette-grey50;
    user-select: none;
  }
}

.step-container {
  padding: $spacing-xl 0;
  display: flex;
  margin: 0 auto;
  width: 100%;

  &.width-634 {
    max-width: 634px;
  }

  &.width-644 {
    max-width: 644px;
  }

  &.width-694 {
    max-width: 694px;
  }

  &.width-720 {
    max-width: 720px;
  }

  &.width-789 {
    max-width: 789px;
  }
}

.CloseCircle24Px {
  path {
    fill: $palette-alert600;
  }

  :first-child {
    stroke: $palette-alert600;
  }
}

.step-number {
  height: 20px;
  min-width: 20px;
  width: 20px;
  border-radius: $radius-round;
  margin-left: $spacing-xxs;
  background-color: $palette-grey500;

  &.active {
    background-color: $palette-primary600;
  }

  &.with-margin {
    margin-left: $spacing-xxs;
    margin: calc($spacing-xxs - 2px);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.expandable-item-padding {
  padding: $spacing-m;
}

.expandable-item-padding-xxs-m {
  padding: $spacing-xxs $spacing-m;
}

.text-italic {
  font-style: italic;
}

.hover-button-icon-fill {
  :hover {
    svg {
      path {
        fill: $palette-primary700
      }
    }
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
}


.shine {
  background: $palette-grey100;
  background-image: linear-gradient(to right, $palette-grey100 0%, $palette-grey200 20%, $palette-grey100 40%, $palette-grey100 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px; 
  display: inline-block;
  position: relative; 
  
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards; 
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

.ul-with-dots {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 10px;

  &.grey500 {
    li::marker {
      color: $palette-grey500;
    }
  }
}

.position-static {
  position: static;
}

.chevron-right-grey400 {
  path {
    fill: $palette-grey400;
  }
}

.trash-icon-wrapper {
  width: 46px;
  height: 46px;
  padding: $spacing-s;
  border: 1px solid $palette-grey200;
  border-radius: $radius-s;
  cursor: pointer;
}

.chevron-right {
  cursor: pointer;
  
  svg {
    path {
      fill: $palette-grey400;
    }
  }
  
  &:hover {
    svg {
      path {
         fill: $palette-grey900;
      }
    }
  }
}

.text-nowrap {
  text-wrap: nowrap;
}

